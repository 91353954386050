import ButtonGroup from "@mui/material/ButtonGroup";
import { setBugForSession, createUUID } from "./utils";
import { useState } from "react";
import { BUG_CATEGORIES, PRIORITIES } from "./constants";
import { clearLocalStorageValue } from "./utils";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const BugReportPopup = ({
  closeBugReportPopUp: closeBugReportPopUp,
  resetEntirePage: resetTheEntirePage,
}) => {
  let categories = BUG_CATEGORIES;
  let priorities = PRIORITIES;
  let [selectedCategory, setSelectedCategory] = useState(null);
  let [selectedPriority, setSelectedPriority] = useState(null);
  let [bugCategoryDisabled, setBugCategoryDisabled] = useState(false);
  let [bugPriorityDisabled, setBugPriorityDisabled] = useState(true);
  let [allFieldsSelected, setAllFieldsSelected] = useState(false);
  let [bugDescriptionDisabled, setBugDescriptionDisabled] = useState(true);
  let [bugDescriptionText, setBugDescriptionText] = useState("");
  let [isCancelDialogActive, setIsCancelDialogActive] = useState(false);

  function onBugCategorySelected(event) {
    setSelectedCategory(event.target.value);

    if (!allFieldsSelected) {
      setBugCategoryDisabled(false);
      setBugPriorityDisabled(false);
    }
  }
  function disablePopUpDialog() {
    setIsCancelDialogActive(false);
  }
  function onBugPrioritySelected(event) {
    setSelectedPriority(event.target.value);
    if (!allFieldsSelected) {
      setBugCategoryDisabled(false);
      setBugPriorityDisabled(false);
      setBugDescriptionDisabled(false);
    }
  }

  function onBugDescriptionChange(event) {
    setBugDescriptionText(event.target.value);
    if (event.target.value.length == 0) {
      setAllFieldsSelected(false);
    } else {
      setAllFieldsSelected(true);
    }
  }

  function cancelPopUpDialog() {
    setIsCancelDialogActive(true);
  }

  function resetTheEntirePage() {
    clearLocalStorageValue("recordButtonCaption");

    window.location.reload();

    setIsCancelDialogActive(false);
  }

  function canceledRecording() {
    window.localStorage.removeItem("recordButtonCaption");
    window.localStorage.removeItem("disableButtonsOnRecord");
    resetTheEntirePage();
  }

  async function onSaveButtonClicked() {
    try {
      await setBugForSession({
        id: createUUID(),
        category: selectedCategory,
        priority: selectedPriority,
        description: bugDescriptionText,
      });
      window.localStorage.removeItem("recordButtonCaption");
      window.localStorage.removeItem("disableButtonsOnRecord");
      resetTheEntirePage();
    } catch (error) {
      console.error("Error setting bug for session:", error);
      resetTheEntirePage();
    }
  }

  return (
    !closeBugReportPopUp && (
      <>
        <Card sx={{ borderRadius: "15px" }} elevation={3}>
          <Grid container xs={12} md={12} justifyContent="left">
            <Grid item xs={1} md={1} />
            <Grid
              item
              xs={3}
              md={3}
              sx={{ marginLeft: "-20px", marginRight: "20px" }}
            >
              {/*Below is the part that generates the bug category radio buttons */}
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                  {categories[0]}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedCategory}
                  name="radio-buttons-group"
                  sx={{ marginTop: "10px" }}
                >
                  {categories[1].map((category, i) => (
                    <FormControlLabel
                      value={category}
                      control={<Radio />}
                      label={category}
                      disabled={bugCategoryDisabled}
                      key={i}
                      onChange={onBugCategorySelected}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} md={1}>
              <Divider orientation="vertical" sx={{ marginRight: 20 }} />
            </Grid>
            <Grid item xs={2} md={2}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "10px",
                  }}
                >
                  {priorities[0]}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedPriority}
                  name="radio-buttons-group"
                  sx={{ marginTop: "10px" }}
                  veelooka="bug priority"
                  veelookastate={selectedPriority}
                >
                  {priorities[1].map((category, i) => (
                    <FormControlLabel
                      value={category}
                      control={<Radio />}
                      label={category}
                      disabled={bugPriorityDisabled}
                      key={i}
                      onChange={onBugPrioritySelected}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={5} md={5} sx={{ background: "none" }}>
              <TextField
                id="outlined-multiline-static"
                multiline
                veelooka="bug description"
                rows={13}
                fullWidth
                placeholder="Write a few words on how you found the bug and what it does..."
                disabled={bugDescriptionDisabled}
                onChange={onBugDescriptionChange}
              />
            </Grid>
          </Grid>
        </Card>
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "20px",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            onClick={cancelPopUpDialog}
            sx={{ background: "#42a4dc" }}
            veelooka="BugReportPopup/cancel"
            veelookastate="cancelReport"
          >
            Cancel Record
          </Button>
          <Button
            sx={{
              justifyContent: "flex-end",
              justifyItems: "right",
              justifySelf: "right",
              justify: "right",
              marginLeft: "auto",
              float: "right",
              background: "#42a4dc",
            }}
            variant="contained"
            disabled={!allFieldsSelected}
            onClick={onSaveButtonClicked}
            veelooka="BugReportPopup/bugDescription/save"
            veelookastate="submitReport"
          >
            Submit Bug Report
          </Button>
        </ButtonGroup>
        <Dialog open={isCancelDialogActive} onClose={""}>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel the recording? All actions taken
              will not be submitted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={disablePopUpDialog}>No</Button>
            <Button autoFocus onClick={canceledRecording}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};
export default BugReportPopup;
