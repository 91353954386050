import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import bulletPoints from "./modalBulletPoints";
import { TabPanel } from "../specSheet/SpecSheet";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { reportEvent } from "../veelookaEngine";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Guidelines({ isOpen, handleClose, wasStarted }) {
  const guidelinesText = `Hi There!

Before you open the app, read thoroughly the app specification and test only within its guidelines.
Don’t try to fail the app in other ways
  
If you find a problem, try to understand its scope, the root cause and then report a bug. Do not report more than one bug per problem.
  
There are several system options that will help you with the app testing, as detailed below.`;

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);
  const [hasVisitedAllSections, setHasVisitedAllSections] = useState(false);

  useEffect(() => {
    if (currentSection === bulletPoints.length - 1 && !hasVisitedAllSections) {
      setHasVisitedAllSections(true);
    }
  }, [currentSection, hasVisitedAllSections]);

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setCurrentSection(newValue);
  };

  const handleContinueClick = () => {
    setCurrentSection(currentSection + 1); // Move to the next section
    setSelectedCategory(currentSection + 1); // Update selectedCategory state to switch tabs
  };

  const handleCloseModal = (event, reason) => {
    if (
      !hasVisitedAllSections
    ) {
      // Prevent closing the modal if "Continue" button is displayed
      return;
    }
    reportEvent("click", "guidelinesModal/guidelinesModalClosed");
    handleClose();
  };

  const closeOrContinue = () => {
    return hasVisitedAllSections ? (
      <Button
        variant="contained"
        sx={{ position: "absolute", right: 10, bottom: 20 }}
        onClick={(e,r) => handleCloseModal(e)}
        veelooka="guidelines/continueButton"
      >
        Close
      </Button>
    ) : (
      <Button
        variant="contained"
        sx={{ position: "absolute", right: 10, bottom: 20 }}
        onClick={handleContinueClick}
        veelooka="guidelines/continueButton"
      >
        Continue
      </Button>
    );
  };

  return (
    <Modal open={isOpen} onClose={(e,r) => handleCloseModal(e)}>
      <Box sx={style}>
        {wasStarted ? (
          <IconButton
            style={{ position: "absolute", right: 5, top: 5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          ""
        )}
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{ marginBottom: "30px", textAlign: "center" }}
        >
          <strong>Guidelines</strong>
        </Typography>
        <Typography
          sx={{
            mt: 2,
            mb: 3,
            wordWrap: "break-word",
            display: "block",
            whiteSpace: "pre-wrap",
          }}
        >
          {guidelinesText}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: 210,
          }}
        >
          <Tabs
            orientation="vertical"
            scrollButtons={false}
            value={selectedCategory}
            onChange={handleCategoryChange}
            veelooka="SpecSheet/specSheetTab"
            veelookastate={selectedCategory}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              marginTop: "30px",
            }}
          >
            <Tab
              sx={{ alignItems: "baseline" }}
              label={
                <Box sx={{ display: "flex" }}>
                  <ArrowForwardIosIcon sx={{ mr: 1 }} fontSize="small" />

                  <Typography>{bulletPoints[0].text}</Typography>
                </Box>
              }
            />
            <Tab
              sx={{ alignItems: "baseline" }}
              label={
                <Box sx={{ display: "flex" }}>
                  <ArrowForwardIosIcon sx={{ mr: 1 }} fontSize="small" />

                  <Typography>{bulletPoints[1].text}</Typography>
                </Box>
              }
            />{" "}
            <Tab
              sx={{ alignItems: "baseline" }}
              label={
                <Box sx={{ display: "flex" }}>
                  <ArrowForwardIosIcon sx={{ mr: 1 }} fontSize="small" />
                  <Typography>{bulletPoints[2].text}</Typography>
                </Box>
              }
            />
          </Tabs>
          <Box sx={{ p: 2, marginLeft: "80px", flex: 1 }}>
            <TabPanel value={selectedCategory} index={0}>
              <Typography>{bulletPoints[0].subText}</Typography>
            </TabPanel>
            <TabPanel value={selectedCategory} index={1}>
              <Typography>{bulletPoints[1].subText}</Typography>
            </TabPanel>
            <TabPanel value={selectedCategory} index={2}>
              <Typography>{bulletPoints[2].subText}</Typography>
            </TabPanel>
          </Box>
        </Box>
        {closeOrContinue(hasVisitedAllSections)}
      </Box>
    </Modal>
  );
}
