export function getSession() {
  var args = new URLSearchParams(window.location.search);
  var session = args.get("s");
  return session;
}

export function readBugsDataForSession() {
  let bugsString = localStorage.getItem("bugslist-" + getSession()) || "[]";
  let bugsData = JSON.parse(bugsString);
  return bugsData;
}

export function storeBugsDataForSession(bugsData) {
  localStorage.setItem("bugslist-" + getSession(), JSON.stringify(bugsData));
}

export function setBugForSession(bug) {
  return new Promise((resolve, reject) => {
    try {
      reportVeelookaEvent("set_bug_data", "bugsList", bug);
      let bugsData = readBugsDataForSession();
      var found = false;
      for (let c = 0; c < bugsData.length; c++) {
        if (bugsData[c].id == bug.id) {
          found = true;
          bugsData[c] = bug;
          break;
        }
      }
      if (!found) {
        bugsData.push(bug);
      }
      storeBugsDataForSession(bugsData);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export function removeBugForSession(bug) {
  reportVeelookaEvent("remove_bug_data", "bugsList", bug);
  let bugsData = readBugsDataForSession();
  for (let c = 0; c < bugsData.length; c++) {
    if (bugsData[c].id == bug.id) {
      bugsData.splice(c, 1);
      break;
    }
  }
  storeBugsDataForSession(bugsData);
}

export function getRadioButtonCheckedValue(groupName) {
  try {
    return document.querySelector('input[name="' + groupName + '"]:checked')
      .value;
  } catch (ex) {
    console.error(ex);
    return null;
  }
}

export function reportVeelookaEvent() {
  document.reportVeelookaEvent.apply(null, arguments);
}

export function createUUID() {
  //TODO: This is not a good implementation of UUID, add something better later
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export function clearLocalStorageValue(value) {
  try {
    localStorage.removeItem(value);
  } catch (err) {
    console.error(err);
  }
}

export function isLocal() {
  if (process.env.NODE_ENV === "development") return true;
}
