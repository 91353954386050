import {
  isValidStoreItemQuantityEntry,
  bugIsValidStoreItemQuantityEntryButAllowNegativeNumbers,
} from "./inputValidators";

function BugStates() {
  let ret = {};
  ret.paperFailsForThisNumberOfAttempts = 2;
  ret.stickyRocksQuantity = 0;
  ret.doNotQueueMultipleHangsBefore = 0;
  return ret;
}

let bugStates = new BugStates();

export const BugDefinitions = {
  "Paper.onTrashButtonClicked": {
    before: function () {
      bugStates.paperFailsForThisNumberOfAttempts--;
      if (bugStates.paperFailsForThisNumberOfAttempts < 0) {
        //normal execution:
        return { continue: true, arguments: arguments };
      } else {
        //first two calls are buggy, do nothing...
        return { return: null, bug: "no_effect" };
      }
    },
  },
  "Rock.onMinusButtonClicked": {
    before: function () {
      //make the browser hang for 5 seconds
      if (Date.now() > bugStates.doNotQueueMultipleHangsBefore) {
        let delay = 5 * 1000;
        for (let startMs = Date.now(); Date.now() - startMs < delay; );
        //then continue as usual:
      }
      bugStates.doNotQueueMultipleHangsBefore = Date.now() + 2 * 1000;
      return {
        continue: true,
        arguments: arguments,
        bug: "hang_for_2_seconds",
      };
    },
  },
  "Scissors.isValidQuantityEntry": {
    before: function (quantity) {
      var dontAllowEverythingButDoAllowSomeNegativeNumbers =
        bugIsValidStoreItemQuantityEntryButAllowNegativeNumbers(quantity);
      //only log a bug if the number exceeds the defined range:
      if (dontAllowEverythingButDoAllowSomeNegativeNumbers) {
        if (!isValidStoreItemQuantityEntry(quantity)) {
          return { return: true, bug: "no_input_validation" };
        } else {
          return { return: true };
        }
      } else {
        //numbers totally not in range
        return { return: false };
      }
    },
  },
  "Cart.cartItemQuantity": {
    before: function (itemName, quantity) {
      //Removing Rock from cart does not affect the sum price
      if (itemName == "Rock" && quantity != buggedQuantity) {
        var buggedQuantity = Math.max(bugStates.stickyRocksQuantity, quantity);
        bugStates.stickyRocksQuantity = buggedQuantity;
        if (buggedQuantity != quantity) {
          return {
            return: bugStates.stickyRocksQuantity,
            bug: "sum_not_effected_by_rock",
          };
        }
      }
      //Bug does not effect items which are not rock
      return { continue: true, arguments: arguments };
    },
  },
};
