import { BugDefinitions } from './bugDefinitions.js';

export function BugGenerator() {
    function logBug(message){
        document.reportVeelookaEvent("bug", message);
    }

    return {
        wrapWithBugs: (name, originalFunction) => {
            return function () {
                let override = BugDefinitions[name];
                if (!override) {
                    //no overrides, just call the original function
                    return originalFunction.apply(this, arguments);
                } else {
                    let before = override.before.apply(this, arguments);
                    if (before.bug) {
                        logBug( name + ":" + before.bug);
                    }
                    if (before.continue) {
                        //continue to calling the original function:
                        return originalFunction.apply(this, before.arguments);
                    } else {
                        //return whatever result the "before" override return without calling original function
                        return before.return;
                    }
                }
            }
        }

    };

}