import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import storeDemo from "./storeDemo.png";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import specBulletPoins from "./specBulletPoints";
import { reportEvent } from "../veelookaEngine";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@mui/material";

const SpecSheet = ({ isOpen, handleClose, wasStarted }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);
  const [hasVisitedAllSections, setHasVisitedAllSections] = useState(false);

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    setCurrentSection(newValue);
  };

  const handleContinueClick = () => {
    setCurrentSection(currentSection + 1); // Move to the next section
    setSelectedCategory(currentSection + 1); // Update selectedCategory state to switch tabs
  };

  const handleCloseModal = (event, reason) => {
    if (
      !hasVisitedAllSections
    ) {
      // Prevent closing the modal if "Continue" button is displayed
      return;
    }
    reportEvent("click", "specsheetModal/specSheetClosed");
    handleClose();
  };

  useEffect(() => {
    if (
      currentSection === specBulletPoins.length - 1 &&
      !hasVisitedAllSections
    ) {
      setHasVisitedAllSections(true);
    }
  }, [currentSection, hasVisitedAllSections]);

  const closeOrContinue = () => {
    return hasVisitedAllSections ? (
      <Button
        variant="contained"
        sx={{ position: "absolute", right: 10, bottom: 20 }}
        onClick={(e) => handleCloseModal(e)}
        veelooka="specSheet/continueButton"
      >
        Close
      </Button>
    ) : (
      <Button
        variant="contained"
        sx={{ position: "absolute", right: 10, bottom: 20 }}
        onClick={handleContinueClick}
        veelooka="specSheet/continueButton"
      >
        Continue
      </Button>
    );
  };

  return (
    <Modal open={isOpen} onClose={(e,r) => handleCloseModal(e)}>
      <Box sx={style}>
        {wasStarted ? (
          <IconButton
            style={{ position: "absolute", right: 5, top: 5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          ""
        )}
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{ marginBottom: "30px", textAlign: "center" }}
        >
          <strong>App Functional Design</strong>
        </Typography>
        <img src={storeDemo} alt="storeDemo" style={{ maxWidth: "100%" }} />
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: 210,
          }}
        >
          <Tabs
            orientation="vertical"
            scrollButtons={false}
            value={selectedCategory}
            onChange={handleCategoryChange}
            veelooka="SpecSheet/specSheetTab"
            veelookastate={selectedCategory}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              width: "220px",
              marginTop: "30px",
            }}
          >
            <Tab
              sx={{ alignItems: "baseline" }}
              label={
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ minWidth: "40px" }}>
                    <ArrowForwardIosIcon />
                  </Box>{" "}
                  <Typography>{specBulletPoins[0].text}</Typography>
                </Box>
              }
            />
            <Tab
              sx={{ alignItems: "baseline" }}
              label={
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ minWidth: "40px" }}>
                    <ArrowForwardIosIcon />
                  </Box>{" "}
                  <Typography>{specBulletPoins[1].text}</Typography>
                </Box>
              }
            />{" "}
            <Tab
              sx={{ alignItems: "baseline" }}
              label={
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ minWidth: "40px" }}>
                    <ArrowForwardIosIcon />
                  </Box>{" "}
                  <Typography>{specBulletPoins[2].text}</Typography>
                </Box>
              }
            />
          </Tabs>
          <Box sx={{ p: 2, marginLeft: "80px", flex: 1 }}>
            <TabPanel value={selectedCategory} index={0}>
              <Typography>{specBulletPoins[0].subText}</Typography>
            </TabPanel>
            <TabPanel value={selectedCategory} index={1}>
              <Typography>{specBulletPoins[1].subText}</Typography>
            </TabPanel>
            <TabPanel value={selectedCategory} index={2}>
              <Typography>{specBulletPoins[2].subText}</Typography>
            </TabPanel>
          </Box>
        </Box>
        {closeOrContinue(hasVisitedAllSections)}
      </Box>
    </Modal>
  );
};
export default SpecSheet;

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
