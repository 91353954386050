import Typography from "@mui/material/Typography";
import StorePlusMinus from "./StorePlusMinus.png";

const bulletPoints = [
  {
    text: "General View",
    subText: (
      <>
        <Typography component="div">
          The app is composed of two main sections.
        </Typography>
        <ul>
          <li>
            <Typography component="div">
              <strong>‘Store’</strong> - to choose products
            </Typography>
          </li>
          <li>
            <Typography component="div">
              <strong>‘Cart’</strong> - to finalize the acquisition
            </Typography>
          </li>
        </ul>
      </>
    ),
  },
  {
    text: "Store Section",
    subText: (
      <>
        <Typography component="div">
          The user can change the products’ amount in two different ways:
        </Typography>
        <ul>
          <li>
            <Typography component="div">
              By clicking on the <strong>‘+’</strong> or the{" "}
              <strong>‘-’</strong> buttons
            </Typography>
          </li>
          <li>
            <Typography component="div">
              By writing a number in the box
            </Typography>
          </li>
          <li>
            <Typography component="div">
              Note that the maximal value to write in the box is 999
            </Typography>
          </li>
          <img src={StorePlusMinus} alt="StorePlusMinus" />
        </ul>
        <Typography component="div">
          Add a product to the cart by clicking the{" "}
          <strong>‘ADD TO CART’</strong> button
        </Typography>
      </>
    ),
  },
  {
    text: "Cart Section",
    subText: (
      <>
        <Typography component="div">
          After clicking on <strong>‘ADD TO CART’</strong>, the product’s amount
          is presented in the <strong>‘Cart’</strong>
        </Typography>

        <Typography component="div">
          Clear the product’s amount by clicking on the <strong>‘Trash’</strong>{" "}
          icon
        </Typography>

        <Typography component="div">
          Click on the <strong>‘CALCULATE’</strong> button to calculate the
          total weight and price of the items in the <strong>‘Cart’</strong>
        </Typography>

        <Typography component="div">
          Click the <strong>‘REMOVE ALL’</strong> button to clear all items in
          the <strong>‘Cart’</strong>
        </Typography>

        <Typography component="div">
          If the <strong>‘Trash’</strong> icon or <strong>‘REMOVE ALL’</strong>{" "}
          button are clicked after the calculation is done, click{" "}
          <strong>‘CALCULATE’</strong> again to get the updated total price and
          weight
        </Typography>
      </>
    ),
  },
];

export default bulletPoints;
