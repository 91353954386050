
export function isValidStoreItemQuantityEntry(quantity){
    var parsedQuantity=Number(quantity);
    return parsedQuantity >=0 && parsedQuantity<1000 && Math.trunc(parsedQuantity)==parsedQuantity;
}

export function bugIsValidStoreItemQuantityEntryButAllowNegativeNumbers(quantity){
    if(quantity=="-"){
        return true
    }else{
        var parsedQuantity=Number(quantity);
        return parsedQuantity >-1000 && parsedQuantity<1000 && Math.trunc(parsedQuantity)==parsedQuantity;
    }
}