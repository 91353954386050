import React, { useState, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { finishAndSubmitVeelookaTest } from "./veelookaEngine";

const EXAM_DURATION = 3600;

function Timer({ remainingTime }) {
  const [key, setKey] = useState(0); // Key to reset the timer
  const colors = ["#004777", "#F7B801", "#A30000", "#A30000"];
  const colorsTime = [7, 5, 2, 0];
  useEffect(() => {
    // Set key to a random value to force re-render of the timer
    setKey(Math.random());
  }, [remainingTime]);

  const formatTimeInMinutes = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds.remainingTime / 60);
    const seconds = timeInSeconds.remainingTime % 60;

    if (minutes === 0 && seconds < 60) {
      return (
        <div
          style={{ fontSize: "16px", lineHeight: "1.6", textAlign: "center" }}
        >
          Less than <br />
          one minute left
        </div>
      );
    } else {
      return (
        <div
          style={{ fontSize: "16px", lineHeight: "1.6", textAlign: "center" }}
        >
          {minutes} Minutes <br /> Remaining
        </div>
      );
    }
  };

  function checkEnd(timeInSeconds) {
    if (timeInSeconds <= 0) {
      finishAndSubmitVeelookaTest();
    }
  }

  return (
    <CountdownCircleTimer
      key={key}
      isPlaying
      duration={EXAM_DURATION} // Total duration of 1 hour
      initialRemainingTime={remainingTime}
      onUpdate={(remainingTime) => checkEnd(remainingTime)}
      colors={colors}
      colorsTime={colorsTime}
    >
      {(remainingTime) => formatTimeInMinutes(remainingTime)}
    </CountdownCircleTimer>
  );
}

export default Timer;
